<template>
 <sdPageHeader title="Producten"></sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :span="24">
    <WizardBlock>
     <a-row>
      <a-col :span="24">
       <WizardWrapper>
        <Steps
         :current="current"
         :isFinished="isFinished"
         :status="status"
         :steps="steps"
         size="small"
         @onDone="done"
         @onPrev="prev"
        >
         <template #address>
          <sdCards title="Adres selecteren">
           <!--           <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">-->
           <!--            <line :x2="(Math.cos(-45 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(-45 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            <line :x2="(Math.cos(0 * (Math.PI / 180)) * 25)" :y2="30 + Math.sin(0 * (Math.PI / 180)) * 25"-->
           <!--                  stroke="black"-->
           <!--                  x1="0" y1="30"/>-->
           <!--            &lt;!&ndash; If you do not specify the stroke-->
           <!--                 color the line will not be visible &ndash;&gt;-->
           <!--           </svg>-->
           <a-row justify="center">
            <a-col :sm="24" :xs="24">
             <div class="create-account-form">
              <a-form
               :model="addressForm"
               :rules="addressFormRules"
               layout="vertical"
               name="account"
               @finish="globalSearch"
              >
               <a-row :gutter="30">
                <a-col :sm="10" :xs="24">
                 <a-form-item label="Postcode" name="postalCode">
                  <a-input v-model:value="addressForm.postalCode" placeholder=""/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item label="Huisnummer" name="houseNumber">
                  <a-input v-model:value="addressForm.houseNumber"
                           onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                           onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                           placeholder="" type="number"/>
                 </a-form-item>
                </a-col>
                <a-col :sm="4" :xs="24">
                 <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                              name="houseNumberAddition"
                 >
                  <a-select v-model:value="addressForm.houseNumberAddition" :allowClear="true"
                            class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                                    :key="index"
                                    :value="item.value" name="energyAllowSingleFuelElectricity">
                    {{ item.key }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24">
                 <a-form-item label="&nbsp;">
                  <a-button :disabled="disabledGlobalSearchButton||globalSearchLoading||loadingHouseNumberAdditions"
                            :loading="globalSearchLoading||loadingHouseNumberAdditions" class="btn-signin w-100"
                            html-type="submit"
                            size="large"
                            type="primary">
                   Zoeken
                  </a-button>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </div>
            </a-col>
           </a-row>
          </sdCards>
          <a-row v-if="globalSearchData && globalSearchData.address" :gutter="30">
           <a-col :md="14">
            <sdCards title="Klant selecteren">
             <a-row style="padding: 0 !important;">
              <a-col :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Nieuwe klanten</sdHeading>
                <a-row :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;">
                 <a-col :xl="16" :xs="24"
                        class="p-2 mb-2" style="vertical-align: middle;display: table-cell;align-self: center">
                  {{ globalSearchData.address.street }}
                  {{
                   globalSearchData.address.houseNumber
                  }} {{
                   globalSearchData.address.houseNumberAddition ? globalSearchData.address.houseNumberAddition : ''
                  }}<br/>
                  {{ globalSearchData.address.postalCode }} {{ globalSearchData.address.city }} <br/>Nederland
                 </a-col>
                 <a-col :xl="8" :xs="24" justify="end"
                        style="vertical-align: middle;align-self: center;text-align: end">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            style="text-wrap: balance;height: auto;"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'CONSUMER')">
                   Doorgaan als particulier
                  </a-button>
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mb-2 mt-2 w-100"
                            size="large"
                            style="text-wrap: balance;height: auto;"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(globalSearchData.address,'BUSINESS')">
                   Doorgaan als zakelijk
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.relations.length > 0" justify="center">
              <a-col :sm="24" :xs="24" class="mb-10">
               <a-col class="about-connection-wrapper">
                <sdHeading as="h6" class="mb-5" style="font-size:14px">Bestaande klanten</sdHeading>
                <a-row v-for="relation in globalSearchData.relations" :key="relation.id" :gutter="20"
                       style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                 <a-col :xl="18" :xs="24" style="vertical-align: middle;display: table-cell;align-self: center;">
                                          <span><strong>{{ relation.fullName }}</strong>
                                                                                      <span class="status-badge ml-2">{{
                                                                                        relation.type && relation.type === 'BUSINESS' ? 'Zakelijk' : 'Particulier'
                                                                                       }}</span>
                                          </span><br/>
                  <span>{{ relation.street }} {{
                    relation.houseNumber
                   }} {{
                    relation.houseNumberAddition ? relation.houseNumberAddition : ''
                   }}<br/> {{ relation.postalCode }} {{ relation.city }}<br/> Nederland</span><br/>
                 </a-col>
                 <a-col :xl="6" :xs="24"
                        style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                  <a-button :disabled="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            :loading="loadingPreFillRelationOnSolarOrder||loadingFetchRoofData"
                            class="mr-2 mb-2 mt-2 w-100"
                            size="large"
                            type="primary-outlined"
                            @click.prevent="handleSelectRelation(relation,relation.type)"> Selecteren
                  </a-button>
                 </a-col>
                </a-row>
               </a-col>
              </a-col>
             </a-row>
             <a-row v-if="globalSearchData.coc.length > 0" ustify="center">
              <a-col :sm="24" :xs="24" class="mb-2">
               <sdHeading as="h6" class="mb-5" style="font-size:14px">Gevonden in<img
                :src="require(`@/static/img/kvk.svg`)"
                alt="" class="ml-5"/></sdHeading>
               <a-row v-for="coc in globalSearchData.coc" :key="coc.companyCocNumber" :gutter="30"
                      style="padding: 10px !important;border: 1px solid rgba(34,41,47,.125);border-radius:5px;margin-bottom: 8px !important;">
                <a-col :xl="18" :xs="24"
                       class="p-2" style="vertical-align: middle;display: table-cell;align-self: center">
                                          <span><strong>{{ coc.companyName }} - {{
                                            coc.companyCocNumber
                                           }}</strong></span><br/>
                 <span>{{ coc.street }} {{
                   coc.houseNumber
                  }} {{
                   coc.houseNumberAddition ? coc.houseNumberAddition : ''
                  }}<br/> {{ coc.postalCode }} {{ coc.city }}<br/>Nederland</span>
                </a-col>
                <a-col :xl="6" :xs="24" justify="end"
                       style="vertical-align: middle;align-self: center;text-align: end">
                 <a-button :disabled="loadingPreFillRelationOnSolarOrder"
                           :loading="loadingPreFillRelationOnSolarOrder"
                           class="mr-2 mb-2 mt-2 w-100"
                           size="large"
                           style="text-wrap: balance;height: auto;"
                           type="primary-outlined"
                           @click.prevent="handleSelectRelation(coc,'BUSINESS')"> Doorgaan als zakelijk
                 </a-button>
                </a-col>
               </a-row>
              </a-col>
             </a-row>
            </sdCards>
           </a-col>
           <a-col :md="10">
            <sdCards style="text-align: center" title="Locatie">
             <img :src="globalSearchData.streetViewImageFileURL" style="width: 100%;height: auto;border-radius:5px">
            </sdCards>
           </a-col>
          </a-row>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <router-link to="/order/flow">
                 <sdButton class="btn-prev" to="" type="primary">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </router-link>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #product>
          <Main>
           <ShoppingCard
            v-if="order.subType !== 'BATTERY' && order.subType !== 'CHARGINGSTATION' && order.subType !== 'WATERPUMP' "/>
           <a-row>
            <template v-if="order.subType === 'BATTERY'">
             <a-col :lg="7" :md="10" :xl="7" :xs="24" :xxl="5"
                    class="product-sidebar-col">
              <Suspense>
               <template #default>
                <FiltersShop :sub-type="order.subType"/>
               </template>
               <template #fallback>
                <sdCards headless>
                 <a-skeleton :paragraph="{ rows: 22 }" active/>
                </sdCards>
               </template>
              </Suspense>
             </a-col>
             <a-col :lg="17" :md="14" :xs="24" :xxl="19" class="product-content-col">
              <TopToolBox>
               <a-row :gutter="0">
                <a-col :lg="12" :xs="24" :xxl="7">
                 <AutoCompleteStyled class="mb-20" placeholder="Zoeken op naam..." style="width:100%">
                  <a-input v-model:value="productFilters.q" type="input" @change.prevent="onProductSearch">
                   <template #suffix>
                    <sdFeatherIcons type="search"/>
                   </template>
                  </a-input>
                 </AutoCompleteStyled>
                </a-col>
                <a-col :lg="12" :xs="24" :xxl="7">
                 <p class="search-result">Toont {{ fromProduct }}–{{ toProduct }} of {{ productCount }} resultaten</p>
                </a-col>
                <a-col :xs="24" :xxl="10">
                </a-col>
               </a-row>
              </TopToolBox>
              <div
               v-if="(innerWidth <= 991 && innerWidth >= 768) || innerWidth > 575"
               class="product-list-action__viewmode"
              >
               <a-row :gutter="30">
                <a-col v-if="loadingFetchShopProducts" :xs="24">
                 <div style="height:200px;justify-content: center;text-align: center">
                  <a-spin class="mt-4"/>
                 </div>
                </a-col>
                <template v-else-if="products.length">
                 <a-col v-for="(product,index) in products" :key="index" :lg="12"
                        :xs="24" :xxl="8">
                  <Suspense>
                   <template #default>
                    <ProductCardsShop :index="index" :product="product" @next="goToRelation()"/>
                   </template>
                   <template #fallback>
                    <sdCards headless>
                     <a-skeleton :paragraph="{ rows: 22 }" active/>
                    </sdCards>
                   </template>
                  </Suspense>
                 </a-col>
                </template>

                <a-col v-else-if="!loadingFetchShopProducts" :md="24">
                 <sdCards headless>
                  <a-empty description="Geen producten gevonden"/>
                 </sdCards>
                </a-col>

                <a-col :xs="24" class="pb-30">
                 <PaginationWrapper v-if="products.length" style="justify-content:center!important">
                  <a-pagination
                   v-model:current="currentPageProduct"
                   v-model:pageSize="defaultPageSize"
                   :defaultCurrent="1"
                   :showSizeChanger="false"
                   :style="{ marginTop: 10 }"
                   :total="productCount"
                   show-less-items
                   @change="onHandleChangePagination"
                  />
                 </PaginationWrapper>
                </a-col>
               </a-row>
              </div>
              <ActionWrapper>
               <div class="step-action-wrap">
                <div class="step-action-inner">
                 <a-row>
                  <a-row v-if="noProductSelectedWarning" class="w-100">
                   <a-col :xs="24">
                    <sdAlerts
                     :closable="false"
                     :outlined="false"
                     :showIcon="true"
                     description="De order moet minimaal €30 euro zijn om verder te kunnen gaan."
                     message=""
                     type="warning"
                    />
                   </a-col>
                  </a-row>
                  <a-col :xs="24">
                   <div class="steps-action">
                    <sdButton class="btn-prev" type="primary" @click="() => prev()">
                     <sdFeatherIcons size="16" type="arrow-left"/>
                     <span>Vorige</span>
                    </sdButton>
                    <sdButton
                     v-if="order.subType !== 'BATTERY' && order.subType !== 'CHARGINGSTATION' && order.subType !== 'WATERPUMP'"
                     class="btn-next"
                     type="primary"
                     @click.prevent="goToRelation()">
                     <span>Volgende</span>
                     <sdFeatherIcons size="16" type="arrow-right"/>
                    </sdButton>
                   </div>
                  </a-col>
                 </a-row>
                </div>
               </div>
              </ActionWrapper>
             </a-col>
            </template>
            <a-col v-else :lg="24" :md="24" :xs="24" :xxl="24" class="product-content-col">
             <TopToolBox>
              <a-row :gutter="0">
               <a-col :lg="12" :xs="24" :xxl="7">
                <AutoCompleteStyled class="mb-20" placeholder="Zoeken op naam..." style="width:100%">
                 <a-input v-model:value="productFilters.q" type="input" @change.prevent="onProductSearch">
                  <template #suffix>
                   <sdFeatherIcons type="search"/>
                  </template>
                 </a-input>
                </AutoCompleteStyled>
               </a-col>
               <a-col :lg="12" :xs="24" :xxl="7">
                <p class="search-result">Toont {{ fromProduct }}–{{ toProduct }} of {{ productCount }} resultaten</p>
               </a-col>
               <a-col :xs="24" :xxl="10">
               </a-col>
              </a-row>
             </TopToolBox>
             <div
              v-if="(innerWidth <= 991 && innerWidth >= 768) || innerWidth > 575"
              class="product-list-action__viewmode"
             >
              <a-row :gutter="30">
               <a-col v-if="loadingFetchShopProducts" :xs="24">
                <div style="height:200px;justify-content: center;text-align: center">
                 <a-spin class="mt-4"/>
                </div>
               </a-col>
               <template v-else-if="products.length">
                <a-col v-for="(product,index) in products" :key="index" :lg="12"
                       :xs="24" :xxl="8">
                 <Suspense>
                  <template #default>
                   <ProductCardsShop :index="index" :product="product" @next="goToRelation()"/>
                  </template>
                  <template #fallback>
                   <sdCards headless>
                    <a-skeleton :paragraph="{ rows: 22 }" active/>
                   </sdCards>
                  </template>
                 </Suspense>
                </a-col>
               </template>

               <a-col v-else-if="!loadingFetchShopProducts" :md="24">
                <sdCards headless>
                 <a-empty description="Geen producten gevonden"/>
                </sdCards>
               </a-col>

               <a-col :xs="24" class="pb-30">
                <PaginationWrapper v-if="products.length" style="justify-content:center!important">
                 <a-pagination
                  v-model:current="currentPageProduct"
                  v-model:pageSize="defaultPageSize"
                  :defaultCurrent="1"
                  :showSizeChanger="false"
                  :style="{ marginTop: 10 }"
                  :total="productCount"
                  show-less-items
                  @change="onHandleChangePagination"
                 />
                </PaginationWrapper>
               </a-col>
              </a-row>
             </div>
             <ActionWrapper>
              <div class="step-action-wrap">
               <div class="step-action-inner">
                <a-row>
                 <a-row v-if="noProductSelectedWarning" class="w-100">
                  <a-col :xs="24">
                   <sdAlerts
                    :closable="false"
                    :outlined="false"
                    :showIcon="true"
                    description="De order moet minimaal €30 euro zijn om verder te kunnen gaan."
                    message=""
                    type="warning"
                   />
                  </a-col>
                 </a-row>
                 <a-col :xs="24">
                  <div class="steps-action">
                   <sdButton class="btn-prev" type="primary" @click="() => prev()">
                    <sdFeatherIcons size="16" type="arrow-left"/>
                    <span>Vorige</span>
                   </sdButton>
                   <sdButton
                    v-if="order.subType !== 'BATTERY' && order.subType !== 'CHARGINGSTATION' && order.subType !== 'WATERPUMP'"
                    class="btn-next"
                    type="primary"
                    @click.prevent="goToRelation()">
                    <span>Volgende</span>
                    <sdFeatherIcons size="16" type="arrow-right"/>
                   </sdButton>
                  </div>
                 </a-col>
                </a-row>
               </div>
              </div>
             </ActionWrapper>
            </a-col>
           </a-row>
          </Main>
         </template>
         <template #relation>
          <ShoppingCard
           v-if="order.subType !== 'BATTERY' && order.subType !== 'CHARGINGSTATION' && order.subType !== 'WATERPUMP'"/>
          <Relation
           ref="relationComponentRef"
           :disable-is-business="true"
           :enable-birthday="false"
           :is-order-create="true"
           :maxAge="80"
           :minAge="18"
           :relation="order.relation"
           :show-card-save-button="false"
           :show-footer="false"
           :show-global-save-button="true"
           :showHeader="false"
           :showIBAN="false"
           type="ADD"
           @submit-external="handleRelationOnOrder($event)"
          ></Relation>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="primary" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Vorige</span>
                </sdButton>
                <sdButton class="btn-next" type="primary"
                          @click.prevent="$refs.relationComponentRef.submitExternal()">
                 <span>Volgende</span>
                 <sdFeatherIcons size="16" type="arrow-right"/>
                </sdButton>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #summary>
          <Main>
           <a-row :gutter="25" type="flex">
            <a-col :md="10" :xs="24" style="display: flex;flex-flow: column;">
             <Suspense>
              <template #default>
               <RelationSVMSummaryCard :order="order"/>
              </template>
             </Suspense>
            </a-col>
            <a-col :md="14" :xs="24" style="display: flex;flex-flow: column;">
             <ProductCard style="margin-bottom: 30px; height:100%">
              <figure style="text-align: center">
               <img
                :src="order?.subProducts[0]?.imageFileURL"
                alt=""
                style="padding:20px; max-width: 250px"
               />
              </figure>
              <figcaption>
               <sdHeading as="h5" class="product-single-title" style="text-align: center;margin-bottom: 10px">
                {{ order?.subProducts[0]?.name }}
               </sdHeading>
               <sdHeading as="h6" class="product-single-title"
                          style="text-align: center;margin-bottom: 30px;font-size:14px">
                <span
                 style="font-size: 0.9rem;">{{
                  `${new Intl.NumberFormat('nl-NL', {
                   style: 'currency',
                   currency: 'EUR'
                  }).format(order?.subProducts[0]?.subAmount)}`
                 }} excl. btw</span>

               </sdHeading>
              </figcaption>
             </ProductCard>
            </a-col>
            <a-col
             v-if="order.subType==='CHARGINGSTATION' || order.subType !== 'WATERPUMP' && order.subType !== 'WHOLESALE' "
             :md="24" :xs="24"
             style="display: flex;flex-flow: column;">
             <a-col :xs="24">
              <sdCards
               title="Addons">
               <a-row :gutter="25">
                <a-col :md="24" :xs="24">
                 <a-row :gutter="25" style="background-color: #dddddd;border-radius: 5px;margin:10px">
                  <div
                   style="display: contents;">
                   <a-col :xs="14" style="margin: auto">
                    <label>Smart laadkabel 5 meter - &euro; {{ addons[0].amount }} eenmalig excl. btw</label>
                   </a-col>
                   <a-col :xs="10">
                    <ProductCard style="background-color: transparent !important;">
                     <div class="product-single-action cart-single-quantity"
                          style="justify-content: center;margin:0 !important;">
                      <sdButton :disabled="addons[0].quantity < 1" class="btn-dec"
                                type="default"
                                @click.prevent="setAddonQuantity({index:0,type:'min'})">
                       <sdFeatherIcons class="quantity-button" size="12" type="minus"/>
                      </sdButton>
                      <div style="color: #000000; margin-left: 5px;margin-right: 5px;width:30px">
                       <a-input
                        v-model:value="addons[0].quantity"
                        disabled
                        style="padding: 0px 0px;background: transparent;border: none;text-align: center;width:30px"
                        type="input"/>
                      </div>
                      <sdButton class="btn-inc" type="default"
                                @click.prevent="setAddonQuantity({index:0,type:'plus'})">
                       <sdFeatherIcons size="12" type="plus"/>
                      </sdButton>
                     </div>
                    </ProductCard>
                   </a-col>
                  </div>
                 </a-row>
                 <a-row :gutter="25" style="background-color: #dddddd;border-radius: 5px;margin:10px">
                  <div
                   style="display: contents;">
                   <a-col :xs="14" style="margin: auto">
                    <label>Smart laadkabel 7 meter - &euro; {{ addons[4].amount }} eenmalig excl. btw</label>
                   </a-col>
                   <a-col :xs="10">
                    <ProductCard style="background-color: transparent !important;">
                     <div class="product-single-action cart-single-quantity"
                          style="justify-content: center;margin:0 !important;">
                      <sdButton :disabled="addons[4].quantity < 1" class="btn-dec"
                                type="default"
                                @click.prevent="setAddonQuantity({index:4,type:'min'})">
                       <sdFeatherIcons class="quantity-button" size="12" type="minus"/>
                      </sdButton>
                      <div style="color: #000000; margin-left: 5px;margin-right: 5px;width:30px">
                       <a-input
                        v-model:value="addons[4].quantity"
                        disabled
                        style="padding: 0px 0px;background: transparent;border: none;text-align: center;width:30px"
                        type="input"/>
                      </div>
                      <sdButton class="btn-inc" type="default"
                                @click.prevent="setAddonQuantity({index:4,type:'plus'})">
                       <sdFeatherIcons size="12" type="plus"/>
                      </sdButton>
                     </div>
                    </ProductCard>
                   </a-col>
                  </div>
                 </a-row>
                 <a-row :gutter="25" style="background-color: #dddddd;border-radius: 5px;margin:10px">
                  <div
                   style="display: contents;">
                   <a-col :xs="14" style="margin: auto">
                    <label>Wandinstallatie - &euro; {{ addons[1].amount }} eenmalig excl. btw</label>
                   </a-col>
                   <a-col :xs="10">
                    <ProductCard style="background-color: transparent !important;">
                     <div class="product-single-action cart-single-quantity"
                          style="justify-content: center;margin:0 !important;">
                      <sdButton :disabled="addons[1].quantity === 0" class="btn-dec"
                                type="default"
                                @click.prevent="setAddonQuantity({index:1,type:'min'})">
                       <sdFeatherIcons class="quantity-button" size="12" type="minus"/>
                      </sdButton>
                      <div style="color: #000000; margin-left: 5px;margin-right: 5px;width:30px">
                       <a-input
                        v-model:value="addons[1].quantity"
                        disabled
                        style="padding: 0px 0px;background: transparent;border: none;text-align: center;width:30px"
                        type="input"/>
                      </div>
                      <sdButton :disabled="addons[1].quantity === 1" class="btn-inc"
                                type="default"
                                @click.prevent="setAddonQuantity({index:1,type:'plus'})">
                       <sdFeatherIcons size="12" type="plus"/>
                      </sdButton>
                     </div>
                    </ProductCard>
                   </a-col>
                  </div>
                 </a-row>
                 <a-row :gutter="25" style="background-color: #dddddd;border-radius: 5px;margin:10px">
                  <div
                   style="display: contents;">
                   <a-col :xs="14" style="margin: auto">
                    <label>Paalinstallatie - &euro; {{ addons[2].amount }} eenmalig excl. btw</label>
                   </a-col>
                   <a-col :xs="10">
                    <ProductCard style="background-color: transparent !important;">
                     <div class="product-single-action cart-single-quantity"
                          style="justify-content: center;margin:0 !important;">
                      <sdButton :disabled="addons[2].quantity === 0" class="btn-dec"
                                type="default"
                                @click.prevent="setAddonQuantity({index:2,type:'min'})">
                       <sdFeatherIcons class="quantity-button" size="12" type="minus"/>
                      </sdButton>
                      <div style="color: #000000; margin-left: 5px;margin-right: 5px;width:30px">
                       <a-input
                        v-model:value="addons[2].quantity"
                        disabled
                        style="padding: 0px 0px;background: transparent;border: none;text-align: center;width:30px"
                        type="input"/>
                      </div>
                      <sdButton :disabled="addons[2].quantity === 1" class="btn-inc"
                                type="default"
                                @click.prevent="setAddonQuantity({index:2,type:'plus'})">
                       <sdFeatherIcons size="12" type="plus"/>
                      </sdButton>
                     </div>
                    </ProductCard>
                   </a-col>
                  </div>
                 </a-row>
                 <a-row :gutter="25" style="background-color: #dddddd;border-radius: 5px;margin:10px">
                  <div
                   style="display: contents;">
                   <a-col :xs="14" style="margin: auto">
                    <label>Kosten graven en kabel - &euro; {{ addons[3].amount }} per meter excl. btw</label>
                   </a-col>
                   <a-col :xs="10">
                    <ProductCard style="background-color: transparent !important;">
                     <div class="product-single-action cart-single-quantity"
                          style="justify-content: center;margin:0 !important;">
                      <sdButton :disabled="addons[3].quantity < 1" class="btn-dec"
                                type="default"
                                @click.prevent="setAddonQuantity({index:3,type:'min'})">
                       <sdFeatherIcons class="quantity-button" size="12" type="minus"/>
                      </sdButton>
                      <div style="color: #000000; margin-left: 5px;margin-right: 5px;width:30px">
                       <a-input
                        v-model:value="addons[3].quantity"
                        disabled
                        style="padding: 0px 0px;background: transparent;border: none;text-align: center;width:30px"
                        type="input"/>
                      </div>
                      <sdButton class="btn-inc" type="default"
                                @click.prevent="setAddonQuantity({index:3,type:'plus'})">
                       <sdFeatherIcons size="12" type="plus"/>
                      </sdButton>
                     </div>
                    </ProductCard>
                   </a-col>
                  </div>
                 </a-row>
                </a-col>
               </a-row>
              </sdCards>
             </a-col>
             <!--             <sdCards title="Winkelmandje">-->
             <!--              <ProductTable>-->
             <!--               <div class="table-cart table-responsive">-->
             <!--                <a-table :columns="productTableColumns" :dataSource="productTableData" :pagination="false"/>-->
             <!--               </div>-->
             <!--               <div style="text-align: end;padding-right: 155px;padding-top:20px"><span class="cart-single-t-price">Totaal: {{-->
             <!--                 new Intl.NumberFormat('nl-NL', {-->
             <!--                  style: 'currency',-->
             <!--                  currency: 'EUR'-->
             <!--                 }).format(subProducts.total)-->
             <!--                }}-->
             <!--             </span></div>-->
             <!--              </ProductTable>-->
             <!--             </sdCards>-->
            </a-col>
           </a-row>
           <a-row v-if="order.flowQuestions && order.flowQuestions.length > 0" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Product vragen">
              <a-form ref="flowQuestionsForm" :model="{flowQuestions:order.flowQuestions}"
                      layout="vertical">
               <a-row :gutter="25">
                <a-col v-for="(item,index) in order.flowQuestions" :key="index" :md="12" :xs="24">
                 <a-form-item v-if="item.inputType === 'STARTDATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="getDefaultPicker()"
                                  :disabled-date="disabledStartDate"
                                  :format="dateFormat" class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'DATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="moment()" :format="dateFormat"
                                  class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'TEXT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-input v-model:value="item.value" placeholder=""/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'INTEGER' && item.type !== 'PANELCOUNT'"
                              :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                              onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                  <a-input v-model:value="item.value" placeholder="" step="1" type="text"/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'INTEGER' && item.type === 'PANELCOUNT'"
                              :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="[{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}]"
                              onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                  <a-input v-model:value="item.value" :disabled="true" placeholder="" step="1" type="text"/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'SELECT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="{required: true,message: 'Dit veld is verplicht'}">
                  <a-select v-model:value="item.value" :allowClear="true" class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(option,index) in item.inputValues"
                                    :key="index"
                                    :name="['flowQuestions', index, 'value']" :value="option"> {{ option }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </sdCards>
            </a-col>
           </a-row>
           <a-row v-if="order.signatureType === 'SIGNATURE'" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Ondertekenen">
              <a-row :gutter="25">
               <a-col :xs="12" class="signature align-center">
                <Vue3Signature ref="signature1" :disabled="sigState.disabled" :h="'200px'" :sigOption="sigState.option"
                               :w="'450px'"></Vue3Signature>
                <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
                 <div role="alert">Uw handtekening is vereist</div>
                </div>
                <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                 <span>Opnieuw</span>
                </sdButton>
               </a-col>
               <a-col :xs="12" class="mb-5">
                <a-form ref="customerApprovalsForm" :model="order"
                        layout="vertical">
                 <a-form-item v-for="(item,index) in order.product.customerApprovals" :key="index"
                              :name="['product','customerApprovalsValidation', index]"
                              :rules="{ validator: checkCheckBox }" class="checkbox-group"
                              label=""
                              style="padding-bottom:0px;margin-bottom:0px">
                  <a-checkbox v-model:checked="order.product.customerApprovalsValidation[index]"
                              :name="['product','customerApprovalsValidation', index]">
                   {{ item }}
                  </a-checkbox>
                 </a-form-item>
                </a-form>
               </a-col>
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
          </Main>
          <ActionWrapper v-if="!isFinished">
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="light" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Terug</span>
                </sdButton>
                <a-button :disabled="loadingCreateOrder" :loading="loadingCreateOrder" class="btn-signin" size="large"
                          type="primary"
                          @click="handleCreateOrder"> Aanmaken
                </a-button>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
        </Steps>
       </WizardWrapper>
      </a-col>
     </a-row>
    </WizardBlock>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {
 CardWrapper,
 ConnectionCardWrapper,
 ConnectionDetailsWrapper,
 EstimateButtonWrapper,
 FigureWizards,
 NotFoundWrapper,
 OrderSummary,
 PaginationWrapper,
 ProductCard,
 WizardBlock,
 WizardWrapper,
} from './Style';
import {
 AutoCompleteShopStyled,
 AutoCompleteStyled,
 BasicFormWrapper,
 DatePickerWrapper,
 Main,
 ModalMain,
 TopToolBox
} from '../styled';
import Steps from "./component/steps";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watchEffect} from "vue";
import Relation from "../../components/relation/Relation";
import FlowCards from "./component/FlowCards";
import {defaultOptions,} from "../../utility/enums";
import {ActionWrapper} from "../../components/steps/style";
import {checkCheckBox, integerValidator, postalCodeValidator} from "../../utility/validators";
import ConnectionTable from "./component/ConnectionTable";
import RelationSVMSummaryCard from "./component/RelationSVMSummaryCard";
import RelationCard from "./component/RelationCard";
import moment from "moment";
import {Empty} from "ant-design-vue";
import {useRoute, useRouter} from "vue-router";
import ProductSummaryInfoCard from "@/view/orders/component/ProductSummaryInfoCard";
import ProductInfoCard from "@/view/orders/component/ProductInfoCard";
import ProductCardsShop from "@/view/orders/component/ProductCardsShop";
import FiltersShop from "@/view/orders/component/FiltersShop";
import ShoppingCard from "@/view/orders/component/ShoppingCard";
import {InfoCircleOutlined} from "@ant-design/icons-vue";

const productTableColumns = [
 {
  title: 'Product',
  dataIndex: 'product',
  key: 'product',
 },
 {
  title: 'Aantal',
  dataIndex: 'quantity',
  key: 'quantity',
 },
 {
  title: 'Prijs',
  dataIndex: 'total',
  key: 'total',
  width: '150px'
 },
 {
  title: '',
  dataIndex: 'action',
  key: 'action',
  width: '50px'
 },
];

const OrderCreate = {
 name: 'Shop',
 components: {
  InfoCircleOutlined,
  ConnectionTable,
  RelationSVMSummaryCard,
  CardWrapper,
  WizardBlock,
  Main,
  ModalMain,
  Steps,
  FigureWizards,
  WizardWrapper,
  BasicFormWrapper,
  OrderSummary,
  ConnectionDetailsWrapper,
  Relation,
  ConnectionCardWrapper,
  RelationCard,
  ActionWrapper,
  EstimateButtonWrapper,
  FlowCards,
  DatePickerWrapper,
  ProductCard,
  ProductSummaryInfoCard,
  ProductInfoCard,
  TopToolBox,
  NotFoundWrapper,
  PaginationWrapper,
  ProductCardsShop,
  FiltersShop,
  AutoCompleteShopStyled,
  ShoppingCard,
  AutoCompleteStyled,
 },
 data() {
  return {
   customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   dateFormat: 'DD-MM-YYYY',
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 watch: {
  addressForm: {
   handler(value) {
    if ((value.postalCode && value.houseNumber) && (this.selectedAddress.postalCode !== value.postalCode || this.selectedAddress.houseNumber !== value.houseNumber)) {
     this.selectedAddress.postalCode = value.postalCode;
     this.selectedAddress.houseNumber = value.houseNumber;
     clearTimeout(this.debounce)
     this.debounce = setTimeout(() => {
      this.$store.dispatch("houseNumberAdditions", {
       postalCode: this.addressForm.postalCode,
       houseNumber: this.addressForm.houseNumber,
       country: this.addressForm.country,
       type: 'SHOP',
      });
     }, 600)
    }
   },
   deep: true,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const {params} = useRoute();
  const status = ref("process");
  const isFinished = ref(false);
  const current = ref(0);
  const defaultPageSize = ref(12);
  const relationComponentRef = ref();
  const productFilters = reactive(computed(() => state.shop.productFilters));
  const currentPageProduct = reactive(computed(() => state.shop.currentPageProduct));
  const productCount = reactive(computed(() => state.shop.productCount));
  const toProduct = reactive(computed(() => state.shop.toProduct));
  const fromProduct = reactive(computed(() => state.shop.fromProduct));
  const houseNumberAdditionsOptions = computed(() => state.globalSearch.houseNumberAdditions);
  const globalSearchData = computed(() => state.globalSearch.data);
  const globalSearchLoading = computed(() => state.globalSearch.loadingSearch);
  const disabledGlobalSearchButton = computed(() => state.globalSearch.disabledGlobalSearchButton);
  const products = reactive(computed(() => state.shop.products));
  const loadingFetchShopProducts = reactive(computed(() => state.shop.loadingFetchShopProducts));
  const order = reactive(computed(() => {
   return state.shop.order;
  }));
  const loadingCreateOrder = computed(() => state.shop.loadingCreateOrder);
  const loadingPreFillRelationOnShopOrder = computed(() => state.shop.loadingPreFillRelationOnShopOrder);
  const loadingHouseNumberAdditions = computed(() => state.globalSearch.loadingHouseNumberAdditions);
  const customerApprovalsForm = ref();
  const signatureError = ref(false);
  const signature1 = ref();
  const innerWidth = ref(window.innerWidth);
  const noProductSelectedWarning = ref(false);
  const subProducts = reactive(computed(() => state.shop.subProducts));
  const flowQuestionsForm = ref();
  const selectedComponent = reactive({
   selectedComponent: null,
  });

  const rulesAddComponent = {
   selectedComponent: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const next = () => {
   status.value = "process";
   current.value = current.value + 1;
  };

  const prev = () => {
   status.value = "process";
   current.value = current.value - 1;
  };

  const prevEan = () => {
   dispatch('resetCreateOrderConnections');
   status.value = "process";
   current.value = current.value - 1;
  };

  const done = () => {
   const confirm = window.confirm("Are sure to submit order?");
   if (confirm) {
    status.value = "finish";
    isFinished.value = true;
    current.value = 0;
   }
  };

  const form = reactive({
   lastName: '',
   firstName: '',
   middleName: '',
  });

  const addressForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL'
  });
  const addressConnectionForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL',
   type: '',
  });
  const addressFormRules = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: integerValidator,
    },
   ],
   houseNumberAddition: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const globalSearch = () => {
   dispatch('globalSearch', {
    postalCode: addressForm.postalCode,
    houseNumber: addressForm.houseNumber,
    houseNumberAddition: addressForm.houseNumberAddition !== null && addressForm.houseNumberAddition !== 'geen' ? addressForm.houseNumberAddition : null,
    country: 'NL',
    type: 'SHOP',
   });
   dispatch('resetShopOrder');
  };

  const handleSelectRelation = async (data, type) => {
   const relation = data;
   if (type) {
    relation.type = type;
   }
   await dispatch('resetRelationShopOrder');
   await dispatch('preFillRelationOnShopOrder', {value: relation, stepRoute: next});
  };

  const handleRelationOnOrder = async (data) => {
   await dispatch('addRelationToShopOrder', {value: data, stepRoute: next})
  };

  const orderCreateSuccessRoute = () => {
   router.push('/order');
  };

  const handleCreateOrder = () => {
   const orderValidators = []
   signatureError.value = false;
   if (order.value.signatureType === 'SIGNATURE') {
    orderValidators.push(customerApprovalsForm.value.validate());
   }

   if (flowQuestionsForm.value) {
    orderValidators.push(flowQuestionsForm.value.validate());
   }
   Promise.all(orderValidators).then(() => {
    if (order.value.signatureType === 'SIGNATURE') {
     if (!signature1.value.isEmpty()) {
      dispatch('setSignatureShopData', signature1.value.save('image/svg+xml'))
      dispatch('createShopOrder', {
       successRoute: orderCreateSuccessRoute,
       productID: params.productID,
       addons: addons.value
      })
     } else {
      signatureError.value = true;
     }
    } else {
     dispatch('createShopOrder', {
      successRoute: orderCreateSuccessRoute,
      productID: params.productID,
      addons: addons.value
     })
    }
   })
    .catch(() => dispatch('orderCreateShopValidationError'))
  };

  watchEffect(() => {
   const activeElement = document.querySelectorAll(".ant-steps-item-active");
   const successElement = document.querySelectorAll(
    ".ant-steps-item-finish"
   );

   activeElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     if (bgImage.classList.contains("success-step-item")) {
      bgImage.classList.remove("success-step-item");
     } else {
      bgImage.classList.remove("wizard-step-item");
     }
     bgImage.classList.add("wizard-steps-item-active");
    }
   });

   successElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     bgImage.classList.remove("wizard-steps-item-active");
     bgImage.classList.add("success-step-item");
    }
   });
  });

  const pageSize = ref(10);

  const onShowSizeChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const onHandleChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const clearSignature = () => {
   signature1.value.clear()
  }

  let debounceProductSearch = null;
  const onProductSearch = (value) => {
   clearTimeout(debounceProductSearch)
   debounceProductSearch = setTimeout(async () => {
    await dispatch('setProductFilters', {
     q: value.target.value,
     type: 'search'
    })
    await dispatch('fetchShopProducts', {
     productID: params.productID,
     categoryID: productFilters.value.categoryID,
     priceFrom: productFilters.value.priceFrom,
     priceTill: productFilters.value.priceTill,
     q: productFilters.value.q,
    });
   }, 500);
  }

  const onHandleChangePagination = value => {
   dispatch('fetchShopProducts', {
    productID: params.productID,
    categoryID: productFilters.value.categoryID,
    priceFrom: productFilters.value.priceFrom,
    priceTill: productFilters.value.priceTill,
    q: productFilters.value.q,
    page: value
   });
  }

  const goToRelation = () => {
   noProductSelectedWarning.value = true;
   // if ((subProducts.value.data.length > 0 && subProducts.value.total > 30) || (order.value.subType === 'BATTERY' || order.value.subType === 'CHARGINGSTATION' || order.value.subType === 'WATERPUMP')) {
   next()
   // } else {
   //  noProductSelectedWarning.value = true
   // }
  }

  const removeProduct = (index) => {
   dispatch('removeSubProduct', index);
  }

  const setProductQuantity = data => {
   dispatch('setSubProductQuantity', data);
  }

  const productTableData = computed(() =>
   subProducts.value.data.map((product, index) => {
    const {id, imageFileURL, name, quantity, totalAmount, externalReference} = product;
    return {
     key: id,
     product: (
      <div class="cart-single">
       <FigureCart>
        {imageFileURL !== null ? (
         <img width="80" height="auto" src={imageFileURL} alt=""/>
        ) : (
         <img width="80" alt="" style="padding-right:5px"
              src="https://shop.eismann.nl/static/de.eismann.shop.theme/dist/img/product/no-image-nl.jpg"/>
        )}
        <figcaption style="width:100%">
         <div className="cart-single__info">
          {externalReference !== null ? (
           <div>
            <span><small>Artikelnr.: {externalReference}</small></span><br/>
            <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
           </div>
          ) : (
           <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
          )}
         </div>
        </figcaption>
       </FigureCart>
      </div>
     ),
     quantity: (
      <div class="cart-single-quantity" style="min-width: 110px;">
       {quantity < 2 ? (
        <sdButton disabled="disabled" class="btn-dec" type="default"
                  onClick={() => setProductQuantity({index: index, type: 'min'})}>
         <sdFeatherIcons type="minus" size="12"/>
        </sdButton>
       ) : (
        <sdButton class="btn-dec" type="default"
                  onClick={() => setProductQuantity({index: index, type: 'min'})}>
         <sdFeatherIcons type="minus" size="12"/>
        </sdButton>
       )}
       <span style="margin:0 10px">{quantity}</span>
       <sdButton class="btn-inc" type="default" onClick={() => setProductQuantity({index: index, type: 'plus'})}>
        <sdFeatherIcons type="plus" size="12"/>
       </sdButton>
      </div>
     ),
     total: <span class="cart-single-t-price">{new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR'
     }).format((parseInt(quantity) * parseFloat(totalAmount)))}</span>,
     action: (
      <div class="table-action">
       <sdButton
        class="btn-icon"
        to="#"
        size="default"
        type="danger"
        shape="circle"
        transparented
        onClick={() => removeProduct(index)}
       >
        <sdFeatherIcons type="trash-2" size={16}/>
       </sdButton>
      </div>
     ),
    };
   }),
  );

  onMounted(async () => {
   let queryString = window.location.search;
   let urlParams = new URLSearchParams(queryString);
   await dispatch('resetShopOrder');
   await dispatch("cleanGlobalSearch");

   if (urlParams.has('type') && (urlParams.get('type') === 'BATTERY' || urlParams.get('type') === 'CHARGINGSTATION' || urlParams.get('type') === 'WATERPUMP' || urlParams.get('type') === 'ADVERTISEMENT' || urlParams.get('type') === 'REALESTATEAGENT') || urlParams.get('type') === 'WHOLESALE') {
    await dispatch('setOrderSubTypeShop', urlParams.get('type'));
   } else {
    router.push(`/order/flow`);
   }

   await dispatch('fetchShopProducts', {productID: params.productID});
   await dispatch('fetchShopCategories', {productID: params.productID});
   await dispatch('fetchShopFlowQuestions', {productID: params.productID});
  });

  const addons = ref([
   {
    id: 2,
    name: 'Smart laadkabel 5 meter',
    quantity: 0,
    totalAmount: 0,
    amount: 99.16,
   },
   {
    id: 3,
    name: 'Wandinstallatie',
    quantity: 0,
    totalAmount: 0,
    amount: 0,
   },
   {
    id: 4,
    name: 'Paalinstallatie',
    quantity: 0,
    totalAmount: 0,
    amount: 206.60,
   },
   {
    id: 5,
    name: 'Kosten graven en kabel (per meter)',
    quantity: 0,
    totalAmount: 0,
    amount: 37.18,
   },
   {
    id: 8,
    name: 'Smart laadkabel 7 meter',
    quantity: 0,
    totalAmount: 0,
    amount: 123.95,
   },
  ]);

  const setAddonQuantity = data => {
   if (data.type === 'plus') {
    addons.value[data.index].quantity = addons.value[data.index].quantity + 1
    addons.value[data.index].totalAmount = addons.value[data.index].quantity * addons.value[data.index].amount
   } else if (data.type === 'min' && addons.value[data.index].quantity > 0) {
    addons.value[data.index].quantity = addons.value[data.index].quantity - 1
    addons.value[data.index].totalAmount = addons.value[data.index].quantity * addons.value[data.index].amount
   }
  }

  return {
   setAddonQuantity,
   products,
   innerWidth,
   sigState,
   steps: [
    {
     title: "Adres",
     content: "address",
    },
    {
     title: "Product",
     content: "product",
    },
    {
     title: "Klantgegevens",
     content: "relation",
    },
    {
     title: "Overzicht",
     content: "summary",
    },
   ],
   clearSignature,
   signature1,
   addressForm,
   addressConnectionForm,
   houseNumberAdditionsOptions,
   addressFormRules,
   globalSearchData,
   order,
   globalSearchLoading,
   disabledGlobalSearchButton,
   relationComponentRef,
   defaultOptions,
   handleSelectRelation,
   globalSearch,
   done,
   prev,
   next,
   handleRelationOnOrder,
   handleCreateOrder,
   isFinished,
   current,
   onHandleChange,
   onShowSizeChange,
   pageSize,
   form,
   moment,
   loadingPreFillRelationOnShopOrder,
   prevEan,
   customerApprovalsForm,
   checkCheckBox,
   signatureError,
   loadingCreateOrder,
   loadingHouseNumberAdditions,
   selectedComponent,
   rulesAddComponent,
   loadingFetchShopProducts,
   integerValidator,
   debounceProductSearch,
   productFilters,
   onProductSearch,
   productCount,
   currentPageProduct,
   toProduct,
   fromProduct,
   defaultPageSize,
   onHandleChangePagination,
   goToRelation,
   noProductSelectedWarning,
   productTableColumns,
   productTableData,
   subProducts,
   addons,
   flowQuestionsForm,

  };
 },
};

export default OrderCreate;
</script>
<style>
.steps-content {
 display: block !important;
}

.status-badge {
 font-size: 12px;
 padding: 0 12.41px;
 line-height: 1.9;
 font-weight: 500;
 border-radius: 12px;
 text-transform: capitalize;
 display: inline-block !important;
 background-color: #519c79;
 color: #ffffff;
}

.ant-collapse-item {
 margin-bottom: 15px !important;;
}

.ant-collapse .collapse-title {
 width: 100% !important;
 display: flex !important;
 align-items: baseline !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled] {
 color: #000000 !important;;
}

.signature canvas {
 width: 400px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}

.ant-calendar-picker-container {
 z-index: 999999 !important;
}
</style>
