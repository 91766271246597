<template>
 <div v-if="isLoader" class="spin">
  <a-spin/>
 </div>
 <ProductCard v-else style="margin-bottom: 30px">
  <figure style="    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex
;">
   <img v-if="renderData.imageFileURL" :src="renderData.imageFileURL" alt=""
        style="padding:20px 20px 0 20px;max-height: 300px;width: auto !important;"/>
   <img v-else alt="" src="https://shop.eismann.nl/static/de.eismann.shop.theme/dist/img/product/no-image-nl.jpg"
        style="padding:20px 20px 0 20px"/>
  </figure>
  <figcaption>
   <sdHeading as="h5" class="product-single-title" style="min-height: 40px;text-align: center">
    {{
     renderData.name
    }}
   </sdHeading>
   <p v-if="renderData?.description" class="product-single-price" style="color: black;display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; min-height: 66px">
    <small><span v-html="renderData.description"></span></small>
   </p>
   <p v-if="!product.subType || (product.subType!=='BATTERY' && product.subType!=='WHOLESALE')"
      class="product-single-price"
      style="text-align: center;padding:5px 0">
    <span class="product-single-price__new">{{
      new Intl.NumberFormat('nl-NL', {
       style: 'currency',
       currency: 'EUR'
      }).format(renderData.totalAmount)
     }} incl. btw</span>
   </p>
   <p v-if="product.subType && (product.subType==='BATTERY'||product.subType==='WHOLESALE')"
      class="product-single-price"
      style="text-align: center;padding:5px 0">
    <span class="product-single-price__new">{{
      new Intl.NumberFormat('nl-NL', {
       style: 'currency',
       currency: 'EUR'
      }).format((renderData.totalAmount / 1.21) * 1)
     }} excl. btw</span>
   </p>
   <div
    v-if="!product.subType || (product.subType!=='BATTERY' && product.subType!=='CHARGINGSTATION'&& product.subType!=='WATERPUMP')"
    class="quantity-wrapper">
    <div class="product-single-action cart-single-quantity" style="justify-content: center">
     <sdButton :disabled="renderData.quantity < 2" class="btn-dec" type="default"
               @click.prevent="setProductQuantity({index:index,type:'min'})">
      <sdFeatherIcons class="quantity-button" size="12" type="minus"/>
     </sdButton>
     <span style="color: #000000; padding-left: 5px;padding-right: 5px">
      <a-input v-model:value="renderData.quantity"
               disabled
               style="padding: 0px 0px;background: transparent;border: none;text-align: center;width:30px"
               type="input"/>
     </span>
     <sdButton class="btn-inc" type="default" @click.prevent="setProductQuantity({index:index,type:'plus'})">
      <sdFeatherIcons size="12" type="plus"/>
     </sdButton>
    </div>
   </div>
   <div class="product-single-action" style="justify-content: center;padding-top:5px">
    <sdButton :disabled="renderData.quantity < 1" class="btn-cart" size="small"

              type="primary"
              @click.prevent="addProductToCard({index:index,value:renderData})">
     <sdFeatherIcons
      v-if="product.subType !== 'BATTERY' && product.subType!=='CHARGINGSTATION' && product.subType!=='WATERPUMP'"
      size="14"
      type="shopping-bag"/>
     <span>{{
       product.subType !== 'BATTERY' && product.subType !== 'CHARGINGSTATION' && product.subType !== 'WATERPUMP' ? 'In winkelwagen' : 'Selecteren'
      }}</span>
    </sdButton>
   </div>
  </figcaption>
 </ProductCard>
</template>
<script>
import PropTypes from 'vue-types';
import {computed, defineComponent, toRefs} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {ProductCard} from "@/view/orders/Style";

const ProductCardsShop = defineComponent({
 name: 'ProductCardsShop',
 components: {ProductCard},
 props: {
  product: PropTypes.object,
  index: PropTypes.number,
 },
 setup(props, {emit}) {
  const {dispatch} = useStore();
  const isLoader = false;
  const {product} = toRefs(props);
  const renderData = computed(() => product.value);
  const {matched} = useRoute();
  const setProductQuantity = data => {
   dispatch('setProductQuantity', data);
  }

  const addProductToCard = data => {
   if (props?.product?.subType === 'BATTERY' || props?.product?.subType === 'CHARGINGSTATION' || props?.product?.subType === 'WATERPUMP') {
    data['showNotification'] = false;
   } else {
    data['showNotification'] = true;
   }

   dispatch('addProductToCard', data);

   if (props?.product?.subType === 'BATTERY' || props?.product?.subType === 'CHARGINGSTATION' || props?.product?.subType === 'WATERPUMP') {
    emit('next');
   }
  }

  return {
   dispatch,
   renderData,
   isLoader,
   matched,
   setProductQuantity,
   addProductToCard,
  };
 },
});

export default ProductCardsShop;
</script>
<style lang="scss" scoped>
.product-single-action {
 margin: 5px -5px -5px -5px !important;
}

.btn-inc:hover i, .btn-inc:hover svg, .btn-dec:hover i, .btn-dec:hover svg {
 color: #ffffff;
}

.cart-single-quantity button {
 padding: 0 !important;
 background-color: rgb(244, 245, 247);

 :hover {
  color: #ffffff !important
 }
}

</style>
